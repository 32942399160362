import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';
import { useActions } from '../hooks';
import { download as downloadAction } from '../store/actions/remoteFiles';
import { RemoteFile } from '../store/api/apiTypes';
import { RemoteFileDownloadPayload } from '../store/api/remoteFiles';
interface GenericFileListProps {
    noNeedFileDownload?: boolean;
    noNeedFileRemove?: boolean;
    files: RemoteFile[];
    onFileRemove?: () => void;
    onFileDownload?: () => void;
}
const GenericFileList: FC<GenericFileListProps> = ({
    noNeedFileDownload,
    noNeedFileRemove,
    files,
    onFileRemove,
    onFileDownload,
}) => {
    const [download] = useActions([downloadAction.trigger]);
    const handleFileDownload = (p: RemoteFileDownloadPayload) => {
        if (onFileDownload) {
            onFileDownload();
        } else {
            download(p);
        }
    };

    return (
        <>
            {files.map((file) => (
                <div key={file.id} className="uploaded-file">
                    <div className="filename">{file.originalName}</div>
                    <div className="actions">
                        {!noNeedFileDownload && (
                            <Button
                                type="text"
                                icon={<DownloadOutlined />}
                                onClick={() => handleFileDownload({ filename: file.originalName ?? '', id: file.id })}
                            />
                        )}
                        {!noNeedFileRemove && <Button type="text" icon={<DeleteOutlined />} onClick={onFileRemove} />}
                    </div>
                </div>
            ))}
        </>
    );
};

export default GenericFileList;
