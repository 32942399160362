import { serialize } from 'object-to-formdata';
import api from './_client';
import { SearchPaginationQuery } from './';

// Types
import { SubContractor } from './apiTypes';

// Controller Interface
export interface SubContractorCreatePayload extends Omit<SubContractor, 'id'> {}
export interface SubContractorUpdatePayload extends SubContractor {}

export interface SubContractorIdPayload {
    id: SubContractor['id'];
}

export type SubContractorListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: SubContractorListPayload = {}) => {
    return await api.get(`/subContractors`, { params: payload });
};

export const create = async (payload: SubContractorCreatePayload) => {
    const formData = serialize(payload, { indices: true });
    return await api.post(`/subContractors`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const update = async (payload: SubContractorUpdatePayload) => {
    const formData = serialize(payload, { indices: true });
    return await api.put(`/subContractors/${payload.id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const details = async (payload: SubContractorIdPayload) => {
    return await api.get(`/subContractors/${payload.id}`);
};

export const remove = async (payload: SubContractorIdPayload) => {
    return await api.delete(`/subContractors/${payload.id}`);
};

export const downloadAttachment = async (payload: SubContractorIdPayload) => {
    return await api.get(`/subcontractors/download/${payload.id}/attachment`, {
        headers: {
            'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
    });
};
